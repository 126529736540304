<template>
	<form @submit.prevent="handleSubmit(!v$.$invalid)" class="p-grid">
		<Toast />
		<ConfirmDialog></ConfirmDialog>
		
		<div class="grid p-fluid stickBar">
			<div class="col-12">
				<div class="card card-w-title" id="stickBar">
					<Menubar :model="nestedMenuitems">
						<template #end>
							<div class="flex justify-content-end flex-wrap card-container green-container">
								<div class="flex align-items-center justify-content-center font-bold text-gray-500 text-xl m-2">
									OTOMASYON PROJESİ EKİPMAN TALEBİ
								</div>
								<div class="flex align-items-center justify-content-center m-2">
									<Button class="p-button-rounded p-button-danger mr-0 mb-0" v-if="$router.options.history.state.back != null" icon="pi pi-times" @click="$router.go(-1)"/>
								</div>
							</div>
						</template>
					</Menubar>
				</div>
			</div>
		</div>

		<div class="grid">
			<div class="col-12">
				<div class="card">
					<EntityHeader label="OTOMASYON PROJESİ EKİPMAN TALEBİ" :entityName="ENTITY_NAME"></EntityHeader>
					<div class="grid">
						<div class="col-6">
							<div class="field p-fluid">
								<label for="ekipmanAdi">Ekipman Adı <span style="color:red">*</span></label>
								<InputText id="ekipmanAdi" type="text" v-model="mainData.bm_name" :disabled="isDisabled"/>
								<span v-if="v$.mainData.bm_name.$error && submitted">
									<span id="email-error" v-for="(error, index) of v$.mainData.bm_name.$errors" :key="index">
										<small class="p-error">{{error.$message}}</small>
									</span>
								</span>
							</div>
						</div>
						<div class="col-3">
							<div class="field p-fluid">
								<EntityLookup id="bm_ekipmansorumlusu" v-model="mainData.bm_ekipmansorumlusuName" ref="entity_bm_ekipmansorumlusu"
									label="Ekipman Sorumlusu" entityName="bm_teknikkullanicilar" nameField="bm_name" :state="true" :required="true"
									@itemSelected="bm_ekipmansorumlusuSelected = $event" @itemCleared="bm_ekipmansorumlusuSelected = null">
								</EntityLookup>
								<span v-if="v$.mainData.bm_ekipmansorumlusuName.$error && submitted">
									<span id="email-error" v-for="(error, index) of v$.mainData.bm_ekipmansorumlusuName.$errors" :key="index">
										<small class="p-error">{{error.$message}}</small>
									</span>
								</span>
							</div>
						</div>
						<div class="col-3">
							<div class="field p-fluid">
								<label for="adet">Adet <span style="color:red">*</span></label>
								<InputNumber id="adet" v-model="mainData.bm_adet" mode="decimal" locale="tr-TR" :minFractionDigits="0" />
								<span v-if="v$.mainData.bm_adet.$error && submitted">
									<span id="email-error" v-for="(error, index) of v$.mainData.bm_adet.$errors" :key="index">
										<small class="p-error">{{error.$message}}</small>
									</span>
								</span>
							</div>
						</div>
						<div class="col-12">
							<div class="field p-fluid">
								<label for="ozelnot">Özel Not</label>
								<Textarea id="ozelnot" rows="4" :autoResize="false" v-model="mainData.bm_ozelnot" />
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</form>
</template>

<script>
import CrmService from "../service/CrmService";
import { useVuelidate } from "@vuelidate/core";
import { helpers, required } from "@vuelidate/validators";

export default {
	setup: () => ({ v$: useVuelidate() }),
	data() {
		return {
			crmService: null,
			submitted: false,
			OBJECT_TYPE_CODE: 10053,
			ENTITY_NAME: 'bm_otomasyonekipmantalebi',
			ENTITY_SUBJECT: 'Otomasyon Projesi',
			mainData: {},

			nestedMenuitems: [
				{
					label:'Kaydet',
					icon:'pi pi-fw pi-save',
					command: () => { this.OnSave(false); },
				},
				{
					label:'Kaydet & Kapat',
					icon:'pi pi-fw pi-save',
					command: () => { this.OnSave(true); },
				},
			],
		}
	},
	created() {
		this.crmService = new CrmService();
		console.log('created');

		window.addEventListener('scroll', this.handleScroll);

		this.mainData["bm_adet"] = 1;
    },
	computed: {
		otomasyonProjesiId() {
			debugger;
			return this.$route.params.id;
		},
		profileData(){
			return this.$store.getters.getProfile;
		},
		bm_ekipmansorumlusuSelected: {
			get: function () {
				if (this.mainData["bm_ekipmansorumlusuId"]) {
					return { "Value": this.mainData["bm_ekipmansorumlusuId"], "Name": this.mainData["bm_ekipmansorumlusuName"] }
				} else{ return null; }
			},
			set: function(newValue) {
				if (newValue == null) {
					this.mainData["bm_ekipmansorumlusuId"] = null;
					this.mainData["bm_ekipmansorumlusuName"] = null;
				}
				else {
					this.mainData["bm_ekipmansorumlusuId"] = newValue.Value;
					this.mainData["bm_ekipmansorumlusuName"] = newValue.Name;
				}
			}
		},
	},
	methods: {
		async OnSave(kapat) {
			debugger;
			
			this.submitted = true;
			this.v$.$touch();

			if (this.v$.$invalid) {
				this.v$.$errors.forEach(element => {
					this.$toast.add({ severity: 'error', summary: 'Eksik alanlar var.', detail: element.$message, life: 3500 });
				});
			} else {
				try {
					this.mainData["bm_otomasyonprojesiId"] = this.otomasyonProjesiId;
					const response = await this.crmService.getOtomasyonEkipmanTalebiCreate(this.mainData);
					if (response) {
						if (response.hata == true) {
							this.$toast.add({severity:'error', summary: 'Kaydedilemedi', detail: response.hataAciklamasi, life: 5000});
						}else {
							this.$toast.add({severity:'success', summary: 'Bilgi', detail:'Başarıyla Kaydedildi', life: 3000});
							if (window.opener) {
								window.opener.window.postMessage({'tag': 'refresh'}, window.location.origin);
							}
							
							if (kapat) {
								setTimeout(() => {
									window.close();
								}, 1000);
							}
							else {
								setTimeout(() => {
									this.$router.push({ name: this.ENTITY_NAME, params: { id: response.olusturulanId } });
								}, 2000);
							}
						}
					}
				} catch (error) {
					this.$toast.add({severity:'error', summary: 'Eksik alanlar var.', detail: error.message, life: 3500});
				}
			}
		},
		handleScroll() {
			const el = document.getElementById('stickBar');

			if (window.scrollY > 50) {
				el.style.boxShadow = '0px 2px 1px rgba(0, 0, 0, 0.09), 0px 4px 2px rgba(0, 0, 0, 0.09), 0px 8px 4px rgba(0, 0, 0, 0.09), 0px 16px 8px rgba(0, 0, 0, 0.09), 0px 32px 16px rgba(0, 0, 0, 0.09)';
			}
			else {
				el.style.boxShadow = '';
			}
		},
	},
	watch: {
		profileData(val) {
			if (val) {
				if (val.moduller) {
					const filtered = val.moduller.filter(x => x.name == "Otomasyon Projesi");
					if (filtered.length == 0) {
						this.$router.replace({ name: 'accessdenied' });
					}
				}
			}
		}
	},
	validations() {
		return {
			mainData: {
				bm_name: {
					required : helpers.withMessage('Ekipman Adı alanı dolu olmalıdır!', required),
				},
				bm_ekipmansorumlusuName: {
					required : helpers.withMessage('Ekipman Sorumlusu alanı dolu olmalıdır!', required),
				},
				bm_adet: {
					required : helpers.withMessage('Adet alanı dolu olmalıdır!', required),
				},
			},
		}
	}
}
</script>

<style lang="scss" scoped>
.stickBar {
	position: sticky;
    top: 6.2rem;
    z-index: 10;
}
</style>
